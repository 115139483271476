import React from 'react';
import { string, number, bool } from 'prop-types';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { Heading, Placeholder } from '@thd-olt-component-react/core-ui';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { QAContextProvider } from '../context/QAContext';
import { QAResultDesktop } from './QAResult/QAResultDesktop.component';
import { dataModel } from './dataModel';
import './QuestionsAndAnswers.scss';

const QuestionsAndAnswersDesktopComponent = ({ itemId, seoPageNumber, enableExperienceProperty }) => {
  return (
    <div className="grid questions-and-answers" data-component="QuestionsAndAnswersDesktop">
      <div className="qa-wrapper">
        <Heading title="Questions &amp; Answers" underline className="qa-heading" />
        <QAContextProvider
          itemId={itemId}
          seoPageNumber={seoPageNumber}
          enableExperienceProperty={enableExperienceProperty}
        >
          <QueryProvider cacheKey="question-answer-desktop">
            <QAResultDesktop />
          </QueryProvider>
        </QAContextProvider>
      </div>
    </div>
  );
};

QuestionsAndAnswersDesktopComponent.displayName = 'QuestionsAndAnswersDesktop';

QuestionsAndAnswersDesktopComponent.propTypes = {
  itemId: string.isRequired,
  seoPageNumber: number,
  enableExperienceProperty: bool
};

QuestionsAndAnswersDesktopComponent.defaultProps = {
  seoPageNumber: null,
  enableExperienceProperty: false
};

QuestionsAndAnswersDesktopComponent.dataModel = dataModel;

const HydratedComponent = withHydrator(
  {
    delay: 2000,
    id: 'product-section-qa',
    placeholder: (
      <div data-component="QuestionsAndAnswersDesktopPlaceholder">
        <Placeholder
          type="rect"
          height="680px"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
      </div>
    ),
    scrollBuffer: 750,
  },
  QuestionsAndAnswersDesktopComponent
);

const DynamicComponent = withDynamicComponent(HydratedComponent);
export const QuestionsAndAnswersDesktop = withErrorBoundary(DynamicComponent);

QuestionsAndAnswersDesktop.propTypes = {
  itemId: string.isRequired,
  seoPageNumber: number,
  enableExperienceProperty: bool
};

QuestionsAndAnswersDesktop.defaultProps = {
  seoPageNumber: null,
  enableExperienceProperty: false
};

QuestionsAndAnswersDesktop.dataModel = dataModel;
