import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import Markdown from 'markdown-to-jsx';
import {
  Alert,
  Button,
  IconButton,
  Input,
  Skeleton,
  SkeletonLine,
  Typography,
  FormLabel
} from '@one-thd/sui-atomic-components';
import {
  Close, ThumbsUp, ThumbsDown, Search, Checkmark
} from '@one-thd/sui-icons';
import classnames from 'classnames';
import { useMagicApron } from '../../hooks/useMagicApron';
import './BotPrompt.scss';

const USER_QUESTION_CHARACTER_LIMIT = 255;
const keyPressMap = { Enter: false, Shift: false };

export const BotPrompt = ({ itemId }) => {
  const [userQuestion, setUserQuestion] = useState('');
  const [submittedQuestion, setSubmittedQuestion] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const { error, loading, response } = useMagicApron(submittedQuestion, itemId);

  const onUserQuestionSubmit = () => {
    setFeedbackSubmitted(false);
    setSubmittedQuestion(userQuestion);
  };
  const onUserQuestionChange = (event) => {
    if (!(keyPressMap.Enter)) {
      setUserQuestion(event.target.value);
    }
  };

  const onUserQuestionKeyPress = (event) => {
    // Keeps track of all currently pressed keys
    keyPressMap[event.key] = event.type === 'keydown';
    if (keyPressMap.Enter) {
      onUserQuestionSubmit();
    }
  };

  const clearPrompt = () => {
    setUserQuestion('');
    setSubmittedQuestion('');
  };

  const submitFeedback = () => setFeedbackSubmitted(true);

  if (!userQuestion && submittedQuestion) clearPrompt();

  const containerClasses = classnames('sui-p-4 sm:sui-p-8 sui-flex sui-flex-col sm:sui-flex-row '
    + 'sui-gap-2 sm:sui-gap-4 sui-bg-primary sui-w-full sui-mb-4 sui-border-1 sui-border-primary sui-border-solid');

  return (
    <div
      data-component="magic-apron-bot-prompt"
      className={containerClasses}
    >
      <div className="sui-flex sui-items-center">
        <div className="sui-mr-4">
          <div className="sui-flex sui-items-center sui-justify-center sui-bg-brand sui-w-12 sui-h-12 sui-rounded-full">
            <Search color="inverse" />
          </div>
        </div>
        <div className="sui-flex sui-flex-col">
          <FormLabel htmlFor="qa-bot-input">
            <Typography variant="h3">Ask about this product</Typography>
          </FormLabel>
          <Typography variant="body-base">Get an immediate answer with AI</Typography>
        </div>
      </div>
      <div className="sui-flex sui-flex-col sui-p-4 sui-bg-subtle">
        <div className="sui-flex sui-flex-col sm:sui-flex-row">
          <div className="sui-mb-2 sm:sui-grow sm:sui-mr-4">
            <Input
              id="qa-bot-input"
              value={userQuestion}
              onChange={onUserQuestionChange}
              onKeyDown={onUserQuestionKeyPress}
              onKeyUp={onUserQuestionKeyPress}
              fullWidth
              multiline
              placeholder="Type a question"
              inputAttributes={{
                maxLength: USER_QUESTION_CHARACTER_LIMIT,
                resize: 'none'
              }}
              endAdornment={!!userQuestion.length && (
                <IconButton
                  icon={Close}
                  size="small"
                  onClick={clearPrompt}
                  aria-label="Clear question"
                />
              )}
            />
          </div>
          <div className="sui-mb-4">
            <Button
              variant="secondary"
              onClick={onUserQuestionSubmit}
              id="magic_apron_qa_find_it_for_me"
              disabled={loading}
            >
              Get an Answer
            </Button>
          </div>
        </div>
        <div className="sui-flex sui-flex-col sui-mb-2">
          <BotResponse loading={loading} error={error} response={response} />
        </div>
        <div>
          <Typography variant="body-xs" color="subtle" height="tight">
            {/* eslint-disable-next-line max-len */}
            AI-generated from the text of manufacturer documentation. To verify or get additional information, please contact The Home Depot customer service.
          </Typography>
        </div>
        {response && (
          <>
            {!feedbackSubmitted ? (
              <div className="sui-flex sui-items-center sui-mt-4 sui-gap-1">
                <Typography variant="body-base">Was this helpful?</Typography>
                <IconButton id="tnt_magic_apron_qna_thumbs_up" icon={ThumbsUp} onClick={() => submitFeedback()} />
                <IconButton id="tnt_magic_apron_qna_thumbs_down" icon={ThumbsDown} onClick={() => submitFeedback()} />
              </div>
            ) : (
              <div className="sui-flex sui-mt-4 sui-items-center">
                {/* eslint-disable-next-line max-len */}
                <div className="sui-flex sui-justify-center sui-items-center sui-rounded-full sui-shrink-0 sui-w-6 sui-h-6 sui-bg-success sui-mr-2">
                  <Checkmark color="inverse" size="small" />
                </div>
                <Typography variant="body-sm" height="tight" color="success">
                  Thank you. Your feedback helps us improve your online shopping experience.
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

BotPrompt.propTypes = {
  itemId: string
};

BotPrompt.defaultProps = {
  itemId: ''
};

const BotResponse = ({ loading, error, response }) => {
  if (loading) {
    return (
      <Skeleton grow>
        <SkeletonLine variant="multi" numberOfLines={3} />
      </Skeleton>
    );
  }

  if (error) {
    return (
      <div className="sui-mt-3">
        <Alert status="error">
          Apologies, we are unable to fetch the requested information at this moment. Please try
          again later.
        </Alert>
      </div>
    );
  }

  if (response) {
    return (
      <Typography variant="body-base" height="tight" component="div">
        <div className="bot-prompt">
          <div className="sui-mb-2">
            <Typography variant="h6">Answer</Typography>
          </div>
          <Markdown>{response.replaceAll(/\n\n/g, '\n\n &nbsp; \n\n')}</Markdown>
        </div>
      </Typography>
    );
  }

  return null;
};

BotResponse.propTypes = {
  loading: bool,
  error: bool,
  response: string
};

BotResponse.defaultProps = {
  loading: false,
  error: false,
  response: ''
};
